import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import AlbumCard from "@/components/AlbumCard"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"
import NoMatch from "@/components/NoMatch"

import KKContextProvider, { KKContext } from "@/contexts/KK"
import useTranslation from "@/hooks/useTranslation"
import createNodeId from "@/utils/createNodeId"

const KkAlbumPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allKkYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          albums {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            special
          }
        }
      }
    }
  `)
  const t = useTranslation()
  const { albums, ...KkInfo } = queryData.allKkYaml.nodes[0]

  const formatAlbums = albums.map((album) => ({
    ...album,
    isCollected: false,
    id: createNodeId(album.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }
  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image className="img" src={KkInfo.avatar.childImageSharp.fluid} />
          <span>{t(KkInfo.name)}</span>
        </div>
        <MultiLineTabs value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="normalSong" />
          <TextTab label="specialSong" />
        </MultiLineTabs>
        <MultiLineTabs value={value2} onChange={handleChange2}>
          <TextTab label="all" />
          <TextTab label="notCollected" />
          <TextTab label="collected" />
        </MultiLineTabs>
        <KKContextProvider>
          <AlbumCards albums={formatAlbums} tabs={{ value, value2 }} />
        </KKContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default KkAlbumPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
`

const AlbumCards = ({ albums, tabs }) => {
  const { kk } = useContext(KKContext)

  const currentAlbums = useMemo(() => {
    const { value, value2 } = tabs

    const albumsHasMarked = albums.map((album) => {
      const albumHasMarked = kk.filter((id) => id === album.id)
      return {
        ...album,
        isCollected: albumHasMarked.length ? true : false,
      }
    })
    let albumsArr = [...albumsHasMarked]

    if (value === 1) {
      albumsArr = albumsArr.filter((album) => !album.special)
    } else if (value === 2) {
      albumsArr = albumsArr.filter((album) => album.special)
    }

    if (value2 === 1) {
      albumsArr = albumsArr.filter((album) => !album.isCollected)
    } else if (value2 === 2) {
      albumsArr = albumsArr.filter((album) => album.isCollected)
    }

    return albumsArr
  }, [kk, albums, tabs])

  return (
    <StyledAlbumCards>
      {currentAlbums.length ? (
        <div className="album_cards">
          {currentAlbums.map((album) => (
            <AlbumCard album={album} key={album.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledAlbumCards>
  )
}

const StyledAlbumCards = styled.div`
  .album_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`
