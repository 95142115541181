import React, { createContext,useState, useReducer, useEffect } from 'react';
import { KKReducer } from './reducer';

export const KKContext = createContext();

const KKContextProvider = ({children}) => {
  const [hasInit, setHasInit] = useState(false);
  const [kk, dispatch] = useReducer(KKReducer, []);
  useEffect(()=>{
    const localData = localStorage.getItem('kk');
    const localKK = localData ? JSON.parse(localData) : [];
    if(Array.isArray(localKK) && localKK.length){
      dispatch({
        type: 'UPDATE_LOCAL_DATA',
        value: localKK
      })
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('kk', JSON.stringify(kk));
    }
  }, [kk]);
  return (
    <KKContext.Provider value={{ kk, dispatch }}>
      {children}
    </KKContext.Provider>
  );
}
 
export default KKContextProvider;